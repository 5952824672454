export default [
  {
    key: "lead_name",
    label: "Name",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "email",
    label: "Email",
    sortable: true,
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "mobile",
    label: "Phone number",
    class: "text-center",
    visible: true,
  },
  {
    key: "enrol_date",
    label: "Migrated At",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
