<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="updateTable"
    >
      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[3]" />
      </template>
      <template #table>
        <b-table
          slot="table"
          ref="refClientsList"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <span class="ml-2">{{ data.item.client_name }}</span>
          </template>
          <!-- account -->
          <template #cell(account)="data">
            <div>
              <div style="padding-left: 0px; margin-bottom: 0px">
                <router-link
                  v-if="moduleId == 4"
                  :href="'/administration/clients/account/' + data.item.id"
                  >{{ data.item.account }}</router-link
                >
                <span v-else style="font-weight: 100">{{
                  data.item.account
                }}</span>
              </div>
              <div style="padding-left: 0px; margin-bottom: 0px">
                <status-account
                  :account="data.item"
                  :text="true"
                ></status-account>
              </div>
            </div>
          </template>
          <!-- column UT -->
          <template #cell(enrol_date)="data">
            <div>
              <span>{{ data.item.created_by }}</span
              ><br />
              {{ data.item.date_migration | myGlobalDay }}
            </div>
          </template>
          <!-- Tracking -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 4 ||
              currentUser.role_id == 17
            "
            #cell(tracking)="data"
          >
            <feather-icon
              @click="
                openTrackingModal({
                  account: data.item.account,
                  client_name: data.item.client_name,
                  id: data.item.id,
                })
              "
              class="clickable text-warning"
              size="20"
              icon="AlignJustifyIcon"
            />
          </template>
          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 4 ||
              currentUser.role_id == 17
            "
            #cell(actions)="data"
          >
            <feather-icon
              @click="
                openRequestModal({
                  account: data.item.account,
                  client_name: data.item.client_name,
                  id: data.item.id,
                  user_created: data.item.user_id,
                })
              "
              class="clickable text-success"
              size="20"
              icon="EyeIcon"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <tracking-modal
      v-if="isTrackingModalOpened"
      :current-client="currentClient"
      @close="closeTrackingModal()"
    />
    <request-modal
      v-if="isRequestModalOpened"
      :current-client="currentClient"
      :new-client-type-id="newClientTypeId"
      @close="closeRequestModal()"
      @update="updateTable()"
    />
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import fields from "@/views/ce-digital/sub-modules/settings/views/migrations/data/fields.clients.data";

// service
// import ClientService from '@/views/boost-credit/services/clients.service'
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import FiltersClientsData from "@/views/ce-digital/sub-modules/settings/views/migrations/data/filters.clients.data";
import TrackingModal from "@/views/ce-digital/sub-modules/settings/views/migrations/modal/TrackingModal.vue";
import RequestModal from "@/views/ce-digital/sub-modules/settings/views/migrations/modal/RequestModal.vue";
import MigrationService from "@/views/ce-digital/sub-modules/settings/views/migrations/services/migrations.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FilterSlot,
    StatusPayment,
    StatusAccount,
    ActionsTable,
    TrackingModal,
    RequestModal,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      clients: null,
      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name or phone number...",
        model: "",
      },
      advisor: "",
      items: [],
      isTrackingModalOpened: false,
      isRequestModalOpened: false,
      currentClient: null,
    };
  },
  methods: {
    updateTable() {
      this.$refs.refClientsList.refresh();
    },
    async myProvider(ctx) {
      let sortBy = 6;
      let sortDirection = "desc";
      if (ctx.sortBy === "lead_name") {
        sortBy = 1;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "account") {
        sortBy = 3;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "enrol_date") {
        sortBy = 6;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "email") {
        sortBy = 2;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }
      try {
        let module = 0;
        switch (parseInt(this.moduleId)) {
          case 2:
            module = this.program;
            break;
          case 4:
            module = this.program;
            break;
          case 3:
            module = 1;
            break;
          case 7:
            module = 2;
            break;
          case 6:
            module = 3;
            break;
          case 5:
            module = 4;
            break;
          case 8:
            module = 5;
            break;
          case 10:
            module = 6;
            break;
        }
        if (parseInt(this.moduleId) != 2 && parseInt(this.moduleId) != 4) {
          if (
            this.currentUser.role_id == 1 ||
            this.currentUser.role_id == 2 ||
            (this.currentUser.role_id == 4 && this.moduleId == 7)
          ) {
            this.advisor = this.filters[2].model;
          } else if (this.currentUser.role_id == 3) {
            this.advisor = this.currentUser.user_id;
          } else {
            this.advisor = null;
          }
        } else {
          this.advisor = this.filters[2].model;
        }
        const params = {
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: 3,
          orderby: sortBy,
          order: sortDirection,
          status: this.filters[3].model,
          advisor: this.advisor,
          page: ctx.currentPage,
          tabDigital: this.$route.meta.tabType,
          currentStatusType: this.setCurrentStatusType, // (1)Regular (2)Digital - Get all Regular clients waiting for approval, NULL for Tabs different from "Pending"
        };
        const data = await MigrationService.getClientMigration(params);
        this.items = data.data.data;
        this.clients = this.items;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    openRequestModal(id) {
      this.isRequestModalOpened = true;
      this.currentClient = id;
    },
    closeRequestModal() {
      this.isRequestModalOpened = false;
      this.currentClient = null;
    },
    async getAllAdvisors() {
      let params = {
        idmodule: 6,
        iduser: this.currentUser.user_id,
        idrole: this.currentUser.role_id ? this.currentUser.role_id : 1,
      };
      const data = await MigrationService.getAllAdvisors(params);
      let firstOption = {
        user_name: "All",
        id: 0,
      };
      let newData = data;
      newData.unshift(firstOption);
      this.filters[2].options = newData;
    },
    openTrackingModal(id) {
      this.isTrackingModalOpened = true;
      this.currentClient = id;
    },
    closeTrackingModal() {
      this.isTrackingModalOpened = false;
      this.currentClient = null;
    },
    async getAllStatusClientAccount() {
      this.filters[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[3].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      taskCounter: "SidebarStore/G_SIDEBAR_ITEMS",
    }),
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      let fields = this.fields.filter((field) => field.visible);
      if (this.$route.meta.tabType != 1) {
        fields = fields.filter((field) => field.key != "actions");
      }
      return fields;
    },
    paymentType: {
      get() {
        return this.filters[4].model;
      },
      set(value) {
        this.filters[4].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[5].visible = value;
      },
    },
    setCurrentStatusType() {
      /* Client type id is sent to the SP depending if this component is being used from Credit Experts Regular or Digital
      and what Tab is set */
      if (
        this.moduleId === 24 &&
        (this.$route.meta.tabType === 1 || this.$route.meta.tabType === 3)
      ) {
        return 1;
      }
      if (this.moduleId === 24 && this.$route.meta.tabType === 2) {
        return 2;
      }
      if (this.moduleId === 6 && this.$route.meta.tabType === 2) {
        return 1;
      }
      if (
        this.moduleId === 6 &&
        (this.$route.meta.tabType === 1 || this.$route.meta.tabType === 3)
      ) {
        return 2;
      }
      return null;
    },
    newClientTypeId() {
      if (this.moduleId === 6) return 1;
      return 2;
    },
  },
  async created() {
    await this.getAllAdvisors();
    await this.getAllStatusClientAccount();
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
</style>
