<template>
  <b-modal
    v-model="control"
    id="modal-entrances-tracking"
    modal-class="modal-primary"
    title="Tracking"
    title-tag="h3"
    size="lg"
    hide-footer
    @hidden="close"
    centered
  >
    <b-row>
      <b-col cols="6">
        <b-input-group size="sm" class="mb-1">
          <template #prepend>
            <b-input-group-text variant="gradient-primary"
              >ACCOUNT</b-input-group-text
            >
          </template>
          <b-form-input
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="
              isDarkSkin
                ? 'background: #17171A;opacity: 0.7;color:white'
                : 'background: #efefef'
            "
            v-model="currentClient.account"
            disabled="disabled"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <b-input-group size="sm" class="mb-1">
          <template #prepend>
            <b-input-group-text variant="gradient-primary"
              >CLIENT</b-input-group-text
            >
          </template>
          <b-form-input
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="
              isDarkSkin
                ? 'background: #17171A;opacity: 0.7;color:white'
                : 'background: #efefef'
            "
            v-model="currentClient.client_name"
            disabled="disabled"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table responsive :items="items" small :fields="fields">
      <template #cell(full_name)="data">
        <span>{{ data.item.name }}</span>
      </template>
      <template #cell(observation)="data">
        <span>{{ data.item.observation }}</span>
      </template>
      <template #cell(status)="data">
        <span :class="[toggleColorStatus(data.item.status)]">{{
          data.item.status
        }}</span>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.item.created_at | myGlobalWithHour }}</span>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import MigrationsService from "@/views/ce-digital/sub-modules/settings/views/migrations/services/migrations.service";
export default {
  props: {
    currentClient: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.control = true;
    this.items = await MigrationsService.getTrackingMigrationRequest({
      client_account_id: this.currentClient.id,
    });
  },
  data() {
    return {
      control: false,
      fields: [
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "client_name",
          label: "Client",
        },
        {
          key: "observation",
          label: "Observation",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: 'type',
          label: 'Type',
          class: 'text-center'
        }
      ],
      items: [],
    };
  },
  methods: {
    toggleColorStatus(value) {
      switch (value) {
        case "Pending":
          return "text-primary";
          break;
        case "Accepted":
          return "text-success";
          break;
        case "Rejected":
          return "text-danger";
          break;
      }
    },
    close() {
      this.$emit("close");
      this.control = false;
    },
  },
};
</script>